import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'
import FAQs from '../../components/FAQs'
import faqs from '../faqs'
import styled from 'styled-components'

const CustomDiv = styled.div`
  margin-top: 220px;
  padding: 30px;
  border-radius: 8px;

  @media (max-width: 600px) {
    margin-top: -150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const content = {
  backgroundTitle: 'Other Psychology Services',
  subtitle: `Available for NDIS & private assessment, intervention and related services. `,
  title: 'Disability Specialist Services',
  otherOptions: [
    {
      text: 'Mental Health Services',
      to: '/services/mental-health-services'
    },
    {
      text: 'Supervision',
      to: '/services/supervision'
    },
    {
      text: 'Agency Specialist Services',
      to: '/services/training-agency-specialist-services'
    }
  ]
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>
        Disability &amp; NDIS Services - Brisbane & Gold Coast Psychology - Upright
        Performance
      </title>
      <meta
        name="description"
        content=" Rob can assist in meeting your behaviour support and psychology needs
        for children, adolescents and adults including. Rob can provide
        NDIS-funded and private services. All his work is tailored to meet
        client need using evidence-based practices:"
      />
    </Helmet>

    <Hero
      skew={true}
      bgImage={data.womanWaterfall.childImageSharp}
      height="750px"
      noForm={true}
    >
      <CustomDiv>
        <h1>{content.title}</h1>
        <p>{content.subtitle}</p>
        <Flex>
          <Link to="/contact">
            <Button color="#fff" small={true} outline={true}>
              Contact Rob Today
            </Button>
          </Link>
        </Flex>
      </CustomDiv>
    </Hero>

    <Content>
      <h2>Disability Specialist Services</h2>
      <span>
        With over 11 years’ experience in providing psychological services to
        the disability sector, Rob has a proven record in partnering with
        individuals, families, and agencies with the aim of improving the
        quality of life for individuals and their carers.
      </span>

      <p>
        Rob can assist in meeting your behaviour support and psychology needs
        for children, adolescents and adults including. Rob can provide
        NDIS-funded and private services. All his work is tailored to meet
        client need using evidence-based practices:
      </p>

      <ol>
        <li>
          ASSESSMENT: including Functional Behaviour Assessment, Developmental
          and Mental Health Assessment, Transition, Risk Assessment and
          Restrictive Practices.
        </li>
        <li>
          INTERVENTION: Positive Behaviour Support Planning and Implementation,
          Restrictive Practices Implementation, Training and Supervision,
          Counselling, Parenting, Mental Health intervention including
          Acceptance & Commitment Therapy and CBT.
        </li>
        <li>
          CONSULTANCY: Supervision, Staff Training, Crisis Intervention,
          Relationship Management, Change Management.
        </li>
        <li>
          REPORT WRITING: including Assessment Reports, Risk Assessments,
          Planning Reports, Transition Assessments and Individualised Profiles
          and Information Reports.
        </li>
      </ol>

      <Link to="/contact/">
        <Button inverted={true}>Contact Rob Today</Button>
      </Link>
    </Content>
    {/* 
    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="400px"
      darkness="0.4"
    >
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection> */}

    {/* <FAQs
      data={faqs}
      flushTop={true}
      title="Common Behaviour Support Questions"
    /> */}

    <AboutContact noBorder={true} data={data} />
  </MainLayout>
)
export const query = graphql`
  query PBSQuery {
    womanWaterfall: file(
      relativePath: { eq: "positive-behaviour-support.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "happy-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    drSellars: file(relativePath: { eq: "camping.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
